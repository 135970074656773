<template>
    <div style="text-align: left;">
        <el-card>
            <div class="pageCenter apply-container">
                <div>
                    <el-form :model="UserMaterial" :rules="UserMaterialRules" ref="UserMaterial" label-width="120px">
                        <el-form-item label="用户头像：" prop="detailAvatar">
                            <upload-image @uploadImageBack="uploadImageBack">
                                <img v-if="UserMaterial.detailAvatar" :src="this.$oucy.ossUrl+UserMaterial.detailAvatar" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </upload-image>
                        </el-form-item>
                        <el-form-item label="用户昵称" prop="detailNickname">
                            <el-input v-model="UserMaterial.detailNickname" autocomplete="off" placeholder="填写企业店铺名称"></el-input>
                        </el-form-item>
                        <el-form-item label="所在省份">
                            <el-cascader v-model="UserMaterial.idPath" style="width: 100%" :props="cityProps" :placeholder="UserMaterial.cityPlaceholder"></el-cascader>
                        </el-form-item>
                        <el-form-item label="个性签名">
                            <el-input v-model="UserMaterial.detailSign" autocomplete="off" placeholder="填写企业签名"></el-input>
                        </el-form-item>
                        <el-form-item label="个人简介">
                            <div style="height: 250px;">
                                <quill-editor class='editor' ref="myQuillEditor" v-model="UserMaterial.detailPersonalIntro" :options="quillEditorOption" />
                            </div>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="keywordFiltr('updateUserMaterial')" type="primary">保存</el-button>
                            <el-button @click="$oucy.replace('/acc/index', null)">取 消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </el-card>
    </div>
    </div>
</template>
<script>
// 富文本配置对象
const toolbarOptions = [
    ["bold", "italic", ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    //["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
    [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
    //[{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    //[{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
    //[{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    //[{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
    //[{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
    //[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
    //[{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    //[{ font: [] }], // 字体种类-----[{ font: [] }]
    //[{ align: [] }], // 对齐方式-----[{ align: [] }]
    //["clean"], // 清除文本格式-----['clean']
    ["image"] // 链接、图片、视频-----['link', 'image', 'video']
];

import oucy from "../../../util/oucyUtil";
import uploadImage from "@/components/uploadImage";
import { localSet, localGet, localDel } from "@/store/store"
import { userMaterial } from "@/service"
export default {
    name: "EditUser",
    components: {
        uploadImage
    },
    data() {
        return {
            UserMaterialRules: {
                detailAvatar: [
                    { required: true, message: '请设置头像', trigger: 'blur' },
                ],
                detailNickname: [
                    { required: true, message: '请输入用户昵称', trigger: 'blur' },
                ]
            },
            quillEditorOption: {
                placeholder: '填写个人简介',
                theme: "snow",
                modules: {
                    toolbar: toolbarOptions,
                    imageDrop: true,
                    imageResize: {
                        displayStyles: {
                            backgroundColor: "black",
                            border: "none",
                            color: "white"
                        },
                        modules: ["Resize", "DisplaySize", "Toolbar"]
                    },
                },
            },
            cityProps: {
                lazy: true,
                lazyLoad(node, resolve) {
                    const { level } = node;
                    oucy.findChina(level === 0 ? null : node.value).then(res => {
                        resolve(Array.from(res).map(item => ({
                            value: item.chinaId,
                            label: item.chinaName,
                            leaf: !item.hasChildren
                        })));
                    });
                }
            },


            // 用户资料
            UserMaterial: {},

        }
    },
    mounted() {
        this.queryUserMaterial()
    },
    methods: {
        keywordFiltr(fn){
            this.$oucy.keywordFiltr(this.UserMaterial).then(res=>{
               this[fn]() 
            },err=>{})
        },
        // 查询个人资料
        queryUserMaterial() {
            userMaterial.queryUserMaterial({}).then(res => {
                this.UserMaterial = res
                if (res.chinaDto && res.chinaDto.idPath) {
                    this.UserMaterial.idPath = res.chinaDto.idPath
                } else {
                    this.UserMaterial.idPath = []
                }

            })
        },
        // 更新个人资料
        updateUserMaterial() {
            if (this.UserMaterial.idPath && this.UserMaterial.idPath.length) {
                // 地址的传数组最后一个回去给后台
                this.UserMaterial.chinaId = this.UserMaterial.idPath[this.UserMaterial.idPath.length - 1]
            } else {
                this.UserMaterial.chinaId = null
            }
            userMaterial.updateUserMaterial(this.UserMaterial).then(res => {
                this.$message({
                    type: 'success',
                    message: '修改成功!'
                });
                this.$oucy.back()
            })

        },
        // 上传回调
        uploadImageBack(v) {
            console.log(v)
            this.UserMaterial.detailAvatar = v
        }

    }
}
</script>
<style scoped>
.apply-container {
    width: 500px;
    min-height: 600px;
    padding: 0 0 50px 0;
}

.editor {
    line-height: normal !important;
    height: 200px;
}

.editor>>>.ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0;
}

.editor>>>.ql-container.ql-snow {
    border-radius: 0 0 4px 4px;
}

.search-addr-name {
    color: #000000;
}

.search-addr-address {
    color: #5c5b75;
    font-size: 12px;
    padding: 0 10px;
}

.search-addr-tel {
    font-style: italic;
}

.avatar-uploader>>>.el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
    margin: 0 8px 8px 0;
}

.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}

.cropper-content .cropper {
    width: auto;
    height: 300px;
}
</style>